// variable to switch between testnet and polygon
var isMainnetProduction = true;
/**
* @file overview This file contains the contract addresses and ABI's
*/

/**
* @constant {object} CONTRACTS - The contract addresses
*/
export const CONTRACTS = {
    // Token RACE
    TOKEN: isMainnetProduction ? "0xdc66010a5558473Fb91b21666B56E4D13Fd7254A" : '0xd020b1566d4E351D0cF6F3f8111602F337C94dD2',

    // Token USDT
    USDT: isMainnetProduction ? '0x05d032ac25d322df992303dca074ee7392c117b9' : '0x84ff23aad7520F2970F43EB1B567e9A1e0B5a902',

    // Token USDC
    USDC: isMainnetProduction ? '0xb62f35b9546a908d11c5803ecbba735abc3e3eae' : '0xa06019820a5FC8799Ff37AA5c73D4564C88fd4fA',

    // Token USDC
    DAI: isMainnetProduction ? '0x6c851f501a3f24e29a8e39a29591cddf09369080' : '0x9c7e9B3BEa668a22ed4c80240bCc81892aFcAa87',

    // NFT
    NFT_ADDRESS: isMainnetProduction ? '0x93779819c6CaC7Cc625B619B614F639433c9C615' : "0x3d0659Fc724c9f40fE9308A47746De2f945531Ba",

    // Staking
    STAKE_CONTRACT: isMainnetProduction ? "0x2b66610961111bee3ad2417787AA95DCb5EF6C59" : "0xF6092c69CA4b0Ac838fBf012d3D3356d6B28bEdE",

    // Staking (For Funds)
    FUND_STAKE_CONTRACT: isMainnetProduction ? "" : "0x4f2fb86A823F0698596AE1409545d75B853E6cF4",

    // Admin, User Management and DIC Management (Only used at admin side)
    USER_MANAGE: isMainnetProduction ? "0xCf6A32dB8b3313b3d439CE6909511c2c3415fa32" : '0x94c263D4f58611547f6E195A2cd1D7504bd10a08',
    DIC_MANAGE: isMainnetProduction ? "0xf54B2BAEF894cfF5511A5722Acaac0409F2F2d89" : '0x33af4F67eD502632BE6EcD19aFE471E3B14FdCa6',
    ADMIN: isMainnetProduction ? "0x0f33D824d74180598311b3025095727BeA61f219" : '0xCb4da06F4AdBb5a4EdA7e30de8D5dAB5e78286e4',

    // Create Project, Complete Review, Complete Vote, Suggestion term and Approve Suggestions(For Watch, Gold, Car and Art)
    CREATE_PROPOSAL: isMainnetProduction ? "0x17d13c7Fb85795825dF97BE3cF4eE0317911e309" : '0xBDf37F747076732Bc5Cf966e3eB6dE1971e30938',
    DIC_PROPOSAL: isMainnetProduction ? "0xFA440a952EAc1bfabb38272f8cD88ABE6C0985eE" : '0x07de65c9c2F506171b91c6110023b274AADfB856',

    // Create Project, Complete Review, Complete Vote, Suggestion term and Approve Suggestions(For Funds)
    FUND_CREATE_PROPOSAL: isMainnetProduction ? "" : '0xAB4cf85270eeD06AcE945F0b217E54C996565403',
    FUND_DIC_PROPOSAL: isMainnetProduction ? "" : '0x25D246d3C347CD40472a20a44010518a6305B4eC',

    // Watch, GOLD, ASSET
    WATCH_MARKETPLACE: isMainnetProduction ? '0xC5e2063FBb77977a50703fCFFb54f06B4645e6cf' : "0x5f33be93cbf6734e0A3b43E7B0e16f3f0b485120",
    WATCH_DEBT_OFFER_ADDRESS: isMainnetProduction ? '0xBFE15ae2D2a468c320d0075B79d7Bf1e5f9163BE' : "0x86505603a505AbABe05a0233174d944380907d53",
    WATCH_REPAYMENT_ADDRESS: isMainnetProduction ? '0x36eF8d1C6b536C08095ABaAE8a4D3EFCD3653825' : "0xf791415b9fFbceEC1C3030827B1b75973dec08D4",

    // Art - Sale
    ART_SALE_MARKETPLACE: isMainnetProduction ? '0x0eeb25c56F44a854F3cA9Ffe06272194c2069A19' : "0xCCbfbe628CDd1a46c23df3526CDE978c6ed7Edb4",

    // Art - Parcel
    ART_PARCEL: isMainnetProduction ? '0x1483879E808b01F56AFb8C0E23fAe991FEE32dAd' : "0x0433f57Ca2E688e70Cf43B9Adc714881D39989bF",

    // SPV
    SPV_MARKETPLACE: isMainnetProduction ? '0xa81Ec35dF8DB8AebcB56228803Fa278dd7d7155F' : "0xA5C38ED1D4c90d097cBdD39bD9a15Cfb5Caa5bf9",
    SPV_DEBT_OFFER_ADDRESS: isMainnetProduction ? '0x4C40EeC05dD43078E7419f21537dA3C9A0BE0CDD' : "0x97191FBCd916f554C2530FC056494119Bb26C02e",
    SPV_DEBT_REPAYMENT_ADDRESS: isMainnetProduction ? '0x004905e36FC9E194a14493F5AE183f867fe19da0' : "0x1E712fE86f32889B34D6A107636535fD37B487F8",
    SPV_EQUITY_OFFER_ADDRESS: isMainnetProduction ? '0xa66012bDb82585C5697DCff9EFde5662116A2bf1' : "0xD3E808de1CB16D727C0213A0d0cc592df50DA3b9",
    SPV_EQUITY_REPAYMENT_ADDRESS: isMainnetProduction ? '0x66F51B60b8bF6A11B9F27266ac2a35131E61D5Ae' : "0x4a32407Ab6939216ac8A11Fdc5C3Ee88D7b54147",

    // Funds
    FUNDS_MARKETPLACE: isMainnetProduction ? '0x81C0a590450a64409e6651eb13A0fC7ec8FD4B89' : '0x83199Bd664862781aBd1b1cD17d16fA517b76C41',
    FUNDS_EQUITY_OFFER: isMainnetProduction ? '0xA08ce7651404d48ad068ba057E1bFD97Bb96163E' : '0x6ac4e26f2d31020E2ce856AB9FDddF7229c37ebf',
    FUNDS_EQUITY_REPAYMENT: isMainnetProduction ? '0xB8e7d039706bd5415d0509e0e6E40A0F3C655643' : '0x092AD8BF010d67770330eac49e3c4A9A055b8454',

    // Helper module and calculate emi
    HELPER_MODULE: isMainnetProduction ? '0xB9b8858eD6898e68b55cC1E9766674C9D9802675' : "0x96D6623386668325E1A4723972B88392ce11B17a",
    CALCULATE_EMI: isMainnetProduction ? '0xACC3FA34498373eaE9Dc92409D2C67E04F876619' : "0xE0b5bc71B07a102736395462b7a48aF3949D3d0a",
}


/**
 * @constant {object} CONTRACT_ABI - The contract ABI's
 */
export const CONTRACT_ABI = {
    [CONTRACTS.TOKEN]: isMainnetProduction ? require("./abi/TokenRT.json") : require("./abi/Token.json"),
    [CONTRACTS.USDT]: isMainnetProduction ? require("./abi/TokenUSDTRT.json") : require("./abi/TokenUSDT.json"),
    [CONTRACTS.USDC]: isMainnetProduction ? require("./abi/TokenUSDCRT.json") : require("./abi/TokenUSDC.json"),
    [CONTRACTS.DAI]: isMainnetProduction ? require("./abi/TokenDIART.json") : require("./abi/TokenDIA.json"),
    [CONTRACTS.NFT_ADDRESS]: isMainnetProduction ? require("./abi/NFTRT.json") : require("./abi/NFT.json"),
    [CONTRACTS.USER_MANAGE]: isMainnetProduction ? require("./abi/UserRT.json") : require("./abi/User.json"),
    [CONTRACTS.DIC_MANAGE]: isMainnetProduction ? require("./abi/DicManageRT.json") : require("./abi/DicManage.json"),
    [CONTRACTS.ADMIN]: isMainnetProduction ? require("./abi/AdminRT.json") : require("./abi/Admin.json"),
    [CONTRACTS.STAKE_CONTRACT]: isMainnetProduction ? require("./abi/StakeContractRT.json") : require("./abi/StakeContract.json"),
    [CONTRACTS.DIC_PROPOSAL]: isMainnetProduction ? require("./abi/DicProposalRT.json") : require("./abi/DicProposal.json"),
    [CONTRACTS.CREATE_PROPOSAL]: isMainnetProduction ? require("./abi/CreateProposalRT.json") : require("./abi/CreateProposal.json"),
    [CONTRACTS.WATCH_MARKETPLACE]: isMainnetProduction ? require("./abi/WatchMarketplaceRT.json") : require("./abi/WatchMarketplace.json"),
    [CONTRACTS.WATCH_DEBT_OFFER_ADDRESS]: isMainnetProduction ? require("./abi/WatchLoanOfferRT.json") : require("./abi/WatchLoanOffer.json"),
    [CONTRACTS.WATCH_REPAYMENT_ADDRESS]: isMainnetProduction ? require("./abi/WatchLoanRepaymentRT.json") : require("./abi/WatchLoanRepayment.json"),
    [CONTRACTS.ART_SALE_MARKETPLACE]: isMainnetProduction ? require("./abi/ArtSaleMarketplaceRT.json") : require("./abi/ArtSaleMarketplace.json"),
    [CONTRACTS.ART_PARCEL]: isMainnetProduction ? require("./abi/ArtParcelRT.json") : require("./abi/ArtParcel.json"),
    [CONTRACTS.SPV_MARKETPLACE]: isMainnetProduction ? require("./abi/SPVMarketplaceRT.json") : require("./abi/SPVMarketplace.json"),
    [CONTRACTS.SPV_DEBT_OFFER_ADDRESS]: isMainnetProduction ? require("./abi/SPVDebtOfferRT.json") : require("./abi/SPVDebtOffer.json"),
    [CONTRACTS.SPV_DEBT_REPAYMENT_ADDRESS]: isMainnetProduction ? require("./abi/SPVDebtRepaymentRT.json") : require("./abi/SPVDebtRepayment.json"),
    [CONTRACTS.SPV_EQUITY_OFFER_ADDRESS]: isMainnetProduction ? require("./abi/SPVEquityInvestmentRT.json") : require("./abi/SPVEquityInvestment.json"),
    [CONTRACTS.SPV_EQUITY_REPAYMENT_ADDRESS]: isMainnetProduction ? require("./abi/SPVEquityRepaymentRT.json") : require("./abi/SPVEquityRepayment.json"),
    [CONTRACTS.FUNDS_MARKETPLACE]: isMainnetProduction ? require("./abi/FundsMarketplaceRT.json") : require("./abi/FundsMarketplace.json"),
    [CONTRACTS.FUNDS_EQUITY_OFFER]: isMainnetProduction ? require("./abi/FundsOfferRT.json") : require("./abi/FundsOffer.json"),
    [CONTRACTS.FUNDS_EQUITY_REPAYMENT]: isMainnetProduction ? require("./abi/FundsRepaymentRT.json") : require("./abi/FundsRepayment.json"),
    [CONTRACTS.HELPER_MODULE]: isMainnetProduction ? require("./abi/HelperModuleRT.json") : require("./abi/HelperModule.json"),
    [CONTRACTS.CALCULATE_EMI]: isMainnetProduction ? require("./abi/CalculateEMIRT.json") : require("./abi/CalculateEMI.json"),

    // For Funds
    [CONTRACTS.FUND_STAKE_CONTRACT]: isMainnetProduction ? require("./abi/FundStakingContract.json") : require("./abi/FundStakingContract.json"),
    [CONTRACTS.FUND_CREATE_PROPOSAL]: isMainnetProduction ? require("./abi/FundCreateProposal.json") : require("./abi/FundCreateProposal.json"),
    [CONTRACTS.FUND_DIC_PROPOSAL]: isMainnetProduction ? require("./abi/FundDicProposal.json") : require("./abi/FundDicProposal.json"),
}