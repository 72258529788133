import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Box, Grid, Typography, Button, TextField, FormControl, InputAdornment, } from "@mui/material";
import { KeyboardArrowRight, AttachMoney } from "@mui/icons-material";
import { useForm } from "react-hook-form";

// Import custom components for this page
import { NewLogo } from "../../../Components/Images/Images";
import LinearProgressCompo from "./Components/LinearProgressCompo";
import "./Components/Questionnaire.css";
import { GetApi } from "../../../Api/Api";


export default function WatchDetails(props) {

    const [watchScope, setWatchScope] = useState([])
    const [watchType, setWatchType] = useState([])
    const [watchCondition, setWatchCondition] = useState([])

    // Destructuring props to get functions and data required for the component
    const { handleNext, handleback, getProposalData, updateProposalData, saveProposal, getProgress } = props;

    // Retrieve current proposal data and progress
    var propData = getProposalData();
    let currentProgress = getProgress();


    // useForm hook to handle form validation and submission
    const { register, handleSubmit, formState: { errors }, setValue } = useForm({
        mode: 'onChange',
        defaultValues: {
            watch_condition: propData?.watchInformation?.watch_condition ? propData?.watchInformation?.watch_condition : "",
            type_of_watch: propData?.watchInformation?.type_of_watch ? propData?.watchInformation?.type_of_watch : "",
            watch_scope_of_delivery: propData?.watchInformation?.watch_scope_of_delivery ? propData?.watchInformation?.watch_scope_of_delivery : "",
        }
    });

    /**
     * Calling Api for getting the deopdown values from API.
     */
    useEffect(() => {
        async function getWatchInventory() {
            try {
                // getting response from api
                const res = await GetApi(`/user/watchInventoryData`);
                // setting data
                if (res?.data?.data?.length) {
                    const scopeOfDelivery = res?.data?.data[0]?.scope_of_delivery;

                    const typeOfWatch = res?.data?.data[0]?.type_of_watch;

                    const watchCondition = res?.data?.data[0]?.watch_condition;

                    const formattedOptions = scopeOfDelivery.map(option => ({
                        value: option,
                        label: option
                    }));

                    const formattedOptionsType = typeOfWatch.map(option => ({
                        value: option,
                        label: option
                    }));

                    const formattedOptionsWatchCondition = watchCondition.map(option => ({
                        value: option,
                        label: option
                    }));

                    setWatchScope([
                        { value: '', label: ' Select Scope of Delivery' }, // Default option
                        ...formattedOptions // Spread the options from API
                    ]);
                    setWatchType([
                        { value: '', label: 'Select Type Of Watch' }, // Default option
                        ...formattedOptionsType // Spread the options from API
                    ]);

                    setWatchCondition([
                        { value: '', label: 'Select Condition' }, // Default option
                        ...formattedOptionsWatchCondition // Spread the options from API
                    ]);
                }
            } catch (error) {
                console.log(error)
            }
        }
        getWatchInventory()
    }, [])


    /**
     * Function setting the values in case of back and next in dropdown
     */
    useEffect(() => {
        if (watchScope?.length && propData?.watchInformation?.watch_scope_of_delivery) {
            setValue('watch_scope_of_delivery', propData?.watchInformation?.watch_scope_of_delivery)
        }

        if (watchType?.length && propData?.watchInformation?.type_of_watch) {
            setValue('type_of_watch', propData?.watchInformation?.type_of_watch)
        }

        if (watchCondition?.length && propData?.watchInformation?.watch_condition) {
            setValue('watch_condition', propData?.watchInformation?.watch_condition)
        }
    }, [watchScope, watchType, watchCondition])

    /**
     * Function to handle form submission, updating 'propData' with the form data, and proceeding to the next step or saving and exiting.
     * @param {Object} data - The form data.
     * @param {Object} event - The form submission event.
     */
    const onSubmit = (data, event) => {
        const buttonText = event.nativeEvent.submitter.innerText;
        // Update 'propData' with the form data
        if (propData.watchInformation) {
            propData.watchInformation = { ...propData.watchInformation, ...data };
        } else {
            propData.watchInformation = data;
        }

        // Set watch model as asset title
        propData.asset_title = data.watch_model;

        // Set watch address to null
        propData.asset_address = '';

        // Convert string to numeric for watch estimated value
        const stringWithoutCurrency = data?.watch_estimated.replace(/[^0-9.]/g, ''); // Removes non-numeric characters
        const value = parseFloat(stringWithoutCurrency, 10); // Parses the string as a float
        propData.watchInformation.watch_estimated = value;

        // Update the main page 
        updateProposalData(propData);

        // Proceed to the next step or save and exit based on the button clicked
        if (buttonText.includes("Save and Exit")) {
            SaveAndExit();
        } else {
            handleNext();
        }
    }

    /**
     * Function to save the current proposal data and exit the current step/page.
     */
    const SaveAndExit = () => {
        saveProposal();
    }


    return (
        <Box className="projectProposer questionnaire-page">
            <Box className="questionnaire-section">
                <Grid container spacing={0}>
                    <Grid item xs={12} sm={6} md={6}>
                        <Box className="auth-left">
                            {/* left-title */}
                            <Box className="quests-left-title">
                                <Typography component="h1" className="title">
                                    Next, we need some details of your assets
                                </Typography>
                            </Box>
                            {/* Logo */}
                            <Box className="auth-logo">
                                <Link to="/">
                                    <Box component="img" src={NewLogo} alt="Race logo" />
                                </Link>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <Box className="auth-right quests-right-contents-box" component="form"
                            onSubmit={handleSubmit(onSubmit)}>
                            <Box textAlign={"right"}>
                                <Box component="span" className="icon" ml={1}>
                                    <Button id="my-button" className="btn-rounded btn-text-white btn-blue-600 saveAndExit" type="submit">
                                        Save and Exit
                                    </Button>
                                </Box>
                            </Box>
                            {/*Watch information Form starts from here */}
                            <Box className="questionnaire6 qsn-middle-content" sx={{ pt: 4, mb: 5 }} >
                                <Box className="auth-form" mt={4} >
                                    <Box mb={'30px'}>
                                        <FormControl fullWidth>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                label="Type Of Watch"
                                                id="outlined-select-country-native"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{
                                                    native: true
                                                }}
                                                name='type_of_watch'
                                                {...register("type_of_watch", {
                                                    required: "Type Of Watch is required.",
                                                })}
                                                className='stIf-input'
                                                error={Boolean(errors.type_of_watch)}
                                                helperText={errors.type_of_watch?.message}
                                                sx={{ input: { color: '#64748B !important' } }}
                                            >
                                                {watchType && watchType?.map((option) => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </FormControl>

                                    </Box>
                                    <Grid container spacing={1.5}>
                                        <Grid item xs={12} sm={6} md={6}>
                                            <Box className="form-group label-field">
                                                <TextField
                                                    defaultValue={propData?.watchInformation?.watch_brand}
                                                    autoComplete="on"
                                                    type="text"
                                                    id="outlined-required"
                                                    InputLabelProps={{ shrink: true }}
                                                    placeholder="Enter Brand Here"
                                                    label={'Brand'}
                                                    {...register("watch_brand", {
                                                        required: "brand is required.",
                                                    })}
                                                    error={Boolean(errors.watch_brand)}
                                                    helperText={errors.watch_brand?.message}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6}>
                                            <Box className="form-group label-field">
                                                <TextField
                                                    defaultValue={propData?.watchInformation?.watch_estimated}
                                                    autoComplete="on"
                                                    // type="text"
                                                    id="outlined-required"
                                                    label="Estimate value at purchase"
                                                    InputProps={
                                                        {
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <AttachMoney />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    {...register("watch_estimated", {
                                                        required: "Estimated Value is required.",
                                                        pattern: {
                                                            value: /^(?!0(\.0+)?$)([0-9]*[.])?[0-9]+$/,  // Allow positive numeric values with one dot
                                                            message: "Please enter a valid estimated value greater than 0.",
                                                        },
                                                    })}
                                                    error={Boolean(errors.watch_estimated)}
                                                    onInput={(event) => {
                                                        event.target.value = event.target.value
                                                            .replace(/[^\d.]/g, "")
                                                    }}
                                                    helperText={errors.watch_estimated?.message}
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={1.5}>
                                        <Grid item xs={12} sm={6} md={6}>
                                            <Box className="form-group label-field">
                                                <TextField
                                                    defaultValue={propData?.watchInformation?.watch_model}
                                                    autoComplete="on"
                                                    type="text"
                                                    id="outlined-required"
                                                    InputLabelProps={{ shrink: true }}
                                                    placeholder="Enter Model Here"
                                                    label='Model'
                                                    {...register('watch_model', {
                                                        required: 'Model is required'
                                                    })}
                                                    error={Boolean(errors.watch_model)}
                                                    helperText={errors.watch_model?.message}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6}>
                                            <Box className="form-group label-field">
                                                <TextField
                                                    defaultValue={propData?.watchInformation?.watch_serial_number}
                                                    autoComplete="on"
                                                    type="text"
                                                    id="outlined-required"
                                                    InputLabelProps={{ shrink: true }}
                                                    placeholder="Enter Serial No. Here"
                                                    label='Serial Number'
                                                    {...register("watch_serial_number", {
                                                        required: 'Serial Number is required'
                                                    })}
                                                    error={Boolean(errors.watch_serial_number)}
                                                    helperText={errors.watch_serial_number?.message}
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Box mb={'30px'}>
                                        <FormControl fullWidth>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                label="Scope of Delivery"
                                                id="outlined-select-country-native"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{
                                                    native: true
                                                }}
                                                name='watch_scope_of_delivery'
                                                {...register("watch_scope_of_delivery", {
                                                    required: "Scope of Delivery is required.",
                                                })}
                                                error={Boolean(errors.watch_scope_of_delivery)}
                                                helperText={errors.watch_scope_of_delivery?.message}
                                                className='stIf-input'
                                                sx={{ input: { color: '#64748B !important' } }}
                                            >
                                                {watchScope?.map(option => (
                                                    <option key={option.value} value={option.value}>{option.label}</option>
                                                ))}
                                            </TextField>
                                        </FormControl>
                                    </Box>
                                    <Box>
                                        <FormControl fullWidth>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                label="Condition"
                                                id="outlined-select-country-native"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{
                                                    native: true
                                                }}
                                                name='condition'
                                                {...register("watch_condition", {
                                                    required: "Condition is required.",
                                                })}
                                                error={Boolean(errors.watch_condition)}
                                                helperText={errors.watch_condition?.message}
                                                className='stIf-input'
                                                sx={{ input: { color: '#64748B !important' } }}
                                            >
                                                {watchCondition?.map((condition) => (
                                                    <option key={condition.value} value={condition.value}>
                                                        {condition.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </FormControl>
                                    </Box>
                                </Box>
                            </Box>
                            {/*Watch information Form ends here */}
                            <Box>
                                <Box className="questionnaire-progress" >
                                    <LinearProgressCompo value={currentProgress} />
                                </Box>
                                <Box
                                    sx={{ display: "flex", justifyContent: "space-between" }}
                                    mt={3}
                                >
                                    <Box textAlign={"left"} className="quests6-btn-box">
                                        <Button className='btn-rounded back-btn' onClick={handleback}>Back</Button>
                                    </Box>
                                    <Box textAlign={"right"} className="quests-btn-box">
                                        <Button
                                            id="my-button"
                                            // onClick={handleNext}
                                            endIcon={<KeyboardArrowRight />}
                                            className="btn-rounded btn-text-white btn-blue-600"
                                            type="submit"
                                        >
                                            Next
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box >
    );
}
