import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ArrowBack, PhoneIphone } from "@mui/icons-material";
import { Box, Grid, Typography, TextField, Button, Stack, InputAdornment, IconButton, MenuItem, FormControl, RadioGroup, FormControlLabel, Radio, Autocomplete } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { postApi } from "../../Api/Api";
import { NewLogo } from "../../Components/Images/Images";
import SnackbarAlert from "../../Components/Common/SnackbarAlert";

//country code file
import CountryCodes from "../../Data/countries.json";
import { isSupportedCountry, isValidPhoneNumber } from "libphonenumber-js";

const country_codes = CountryCodes;

export default function ForgotPasswordDIC() {

    // states
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [snackbar, setSnackbar] = useState({ severity: 'success', message: "" });
    const [recoverBy, setRecoverBy] = useState('email');

    // Importing necessary functions from react-hook-form
    const { register, handleSubmit, formState: { errors }, control, watch } = useForm(
        {
            defaultValues: {
                email: "",
                countryCode: country_codes[0].code,
                phoneNumber: "",
            }
        }
    );
    const countryCodeNum = watch("countryCode");
    const phoneNumber = watch("phoneNumber");
    const [countryIso, setCountryIso] = useState("");

    /**
     *  validate phone number with libphonenumber-js library
     * @param {string} value  phone number
     * @param {string} country_iso  country code
     * @returns
     */
    const validatePhoneNumber = (value) => {
        const res = isSupportedCountry(countryIso);
        return isValidPhoneNumber(`+${countryCodeNum}${value}`, countryIso);
    };
    /**
    * Function to handle form submission
    * @param {Object} data - Data submitted from the form
    */
    const onSubmit = (data) => {
        if (recoverBy === 'email') {
            handleClick({ email: data.email });
        } else {
            handleClick({ phoneNumber: data.phoneNumber, countryCode: data.countryCode });
        }
    }

    /**
    * Function to handle click event for sending recovery email
    * @param {string} email - Email address to send the recovery link to
    */
    const handleClick = (payload) => {
        const body = recoverBy === 'email' ? { email: payload.email, type: "email", is_dic: true } : { phone_number: `${payload.phoneNumber}`, country_code: `+${payload.countryCode}`, type: "phone_number", is_dic: true };
        // Sending post request to API for password recovery
        postApi(`/user/forget_password`, body).then((response) => {
            if (response?.data?.code === 200) {
                setOpenSnackbar(true);
                setSnackbar({ severity: 'success', message: recoverBy === 'email' ? "Password reset link sent to email successfully" : "Password reset link sent to phone successfully" });
            } else {
                setOpenSnackbar(true);
                setSnackbar({ severity: 'error', message: response?.data?.message });
            }
        });
    }


    /**
    * Handles snackbar close event
    * @param {*} event - Event triggered by the close action
    * @param {*} reason - Reason for closing the snackbar
    */
    const handleClose = (event, reason) => {
        setOpenSnackbar(false); // Close the snackbar
    };

    return (
        <>
            <Box className="forgotPassword-page auth-page">
                <Box className="auth-section">
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={6}>
                            <Box className="auth-left">
                                <Box className="content">
                                    <Box className="heading-wrap">
                                        <Typography component="h1" className="title">
                                            RACE’s Decentralized Investment Committee
                                        </Typography>
                                        <Typography component="p" className="sub-title">
                                            HELP DRIVE THE RACE PLATFORM • GET REWARDED FOR WORK DONE
                                        </Typography>
                                    </Box>
                                </Box>

                                <Box className="auth-logo">
                                    <Link to="/">
                                        <Box component="img" src={NewLogo} alt="Race logo" />
                                    </Link>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <Box className="auth-right">
                                <Box className="backIcon-btn">
                                    <Link component="a" to="/dic/login">
                                        <ArrowBack />
                                    </Link>
                                </Box>
                                <Box className="auth-form-wrap">
                                    <Box className="heading-wrap">
                                        <Typography component="h1" className="title">
                                            Forgot your password?
                                        </Typography>
                                        <Typography component="p" className="sub-title">
                                            Enter your email address or phone number below to reset your password
                                        </Typography>
                                    </Box>

                                    <FormControl>
                                        {/* <FormLabel id="radio-buttons-group-label">Gender</FormLabel> */}
                                        <RadioGroup
                                            aria-labelledby="radio-buttons-group-label"
                                            defaultValue="female"
                                            name="radio-buttons-group"
                                            row={true}
                                            value={recoverBy}
                                            onChange={(e) => setRecoverBy(e.target.value)}
                                        >
                                            <FormControlLabel value="phone" control={<Radio />} label="Phone Number" />
                                            <FormControlLabel value="email" control={<Radio />} label="Email Address" />
                                        </RadioGroup>
                                    </FormControl>

                                    {/* Start Form */}
                                    <Box
                                        className="auth-form"
                                        mt={4}
                                        component="form"
                                        onSubmit={handleSubmit(onSubmit)}
                                    >
                                        {
                                            recoverBy === 'email' ?
                                                <Box className="form-group label-field">
                                                    <TextField
                                                        type="email"
                                                        id="outlined-required"
                                                        label="Email Address"
                                                        name="email"
                                                        placeholder="Enter your email address"
                                                        {...register("email", {
                                                            required: "E-mail address is required.",
                                                            pattern: {
                                                                value:
                                                                    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                                                message: "Please enter valid email address.",
                                                            },
                                                        })}
                                                        error={Boolean(errors.email)}
                                                        helperText={errors.email?.message}
                                                    />
                                                </Box>
                                                : // else phone number
                                                <>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} sm={5} md={3}>
                                                            <Controller
                                                                name="countryCode"
                                                                control={control}
                                                                defaultValue={country_codes[0].code}
                                                                rules={{
                                                                    required: "Country Code is required",
                                                                }}
                                                                render={({ field: { onChange, value, ref } }) => (
                                                                    <Autocomplete
                                                                        options={country_codes}
                                                                        getOptionLabel={(option) =>
                                                                            `+${option.code} (${option.iso})`
                                                                        }
                                                                        value={
                                                                            country_codes.find(
                                                                                (option) => option.code === value
                                                                            ) || null
                                                                        }
                                                                        onChange={(_, newValue) => {
                                                                            onChange(newValue ? newValue.code : "");
                                                                            setCountryIso(newValue ? newValue.iso : "");
                                                                        }}
                                                                        isOptionEqualToValue={(option, value) =>
                                                                            option.code === value.code
                                                                        }
                                                                        renderInput={(params) => (
                                                                            <TextField
                                                                                {...params}
                                                                                inputRef={ref}
                                                                                label="Country Code"
                                                                                error={Boolean(errors.countryCode)}
                                                                                helperText={errors.countryCode?.message}
                                                                                inputProps={{
                                                                                    ...params.inputProps,
                                                                                    inputMode: "numeric",
                                                                                    type: "text", // Use text to allow proper Autocomplete function
                                                                                    onInput: (event) => {
                                                                                        event.target.value =
                                                                                            event.target.value.replace(
                                                                                                /[^\d]/g,
                                                                                                ""
                                                                                            );
                                                                                    },
                                                                                }}
                                                                            />
                                                                        )}
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={9}>
                                                            <Box className="form-group label-field">
                                                                <TextField
                                                                    // autoComplete="off"
                                                                    id="outlined-required"
                                                                    label="Phone Number"
                                                                    type="text"
                                                                    pattern="[0-9]*"
                                                                    placeholder="Enter your phone number"
                                                                    name="phone_number"
                                                                    {...register("phoneNumber", {
                                                                        required: "Phone number is required.",
                                                                        // pattern: {
                                                                        //   value: /^\d{4,15}$/,
                                                                        //   message:
                                                                        //     "Please enter valid phone number in digits.",
                                                                        // },
                                                                        validate: (value) =>
                                                                            validatePhoneNumber(value, countryCodeNum) ||
                                                                            "Please enter valid phone number",
                                                                    })}
                                                                    // onChange={(e) => setPhoneNumber(e.target.value)}
                                                                    autoComplete="tel" // this line for auto-fill
                                                                    error={Boolean(errors.phoneNumber)}
                                                                    helperText={errors?.phoneNumber?.message}
                                                                    InputProps={{
                                                                        startAdornment: (
                                                                            <InputAdornment position="start">
                                                                                <PhoneIphone className="text-blue-600" />
                                                                            </InputAdornment>
                                                                        ),
                                                                    }}
                                                                    onInput={(event) => {
                                                                        event.target.value = event.target.value
                                                                            .replace(/\D/g, "")
                                                                            .slice(0, 15);
                                                                    }}
                                                                />
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                    <Stack
                                                        className="registeredNumber-des"
                                                        direction={{ xs: "column", sm: "row" }}
                                                        useFlexGap
                                                        flexWrap="wrap"
                                                        alignItems="center"
                                                        justifyContent="space-between"
                                                        mb={3}
                                                    >
                                                        <Typography>Registered Phone Number</Typography>
                                                        <Typography>{validatePhoneNumber(phoneNumber, countryCodeNum) ? `xxxx-xxxx-${phoneNumber?.slice(-4)}` : "xxxx-xxxx-xxxx"}</Typography>
                                                    </Stack>
                                                </>
                                        }


                                        <Box className="btn-wrap">
                                            <Button
                                                variant="contained"
                                                type="submit"
                                                fullWidth
                                                className="btn-rounded btn-blue-600"
                                            >
                                                {/* Send Recovery link via text message */}
                                                Send Recovery link
                                            </Button>
                                        </Box>
                                        <Box className="authForgot-des" mt={4}>
                                            <Typography>
                                                For security, this link expires in 1 hour. Open it
                                                immediately or wait until you're ready to complete the
                                                password reset process before requesting a password reset.
                                            </Typography>
                                        </Box>
                                    </Box>
                                    {/* End Form */}
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <SnackbarAlert open={openSnackbar} message={snackbar.message} severity={snackbar.severity} onClose={handleClose} />
        </>
    );
}