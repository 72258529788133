import React, { useCallback, useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { Link, useNavigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { useForm, Controller } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { isSupportedCountry, isValidPhoneNumber } from "libphonenumber-js";
import { Box, Grid, Typography, TextField, Stack, FormGroup, MenuItem, IconButton, Button, Avatar, InputAdornment, FormControl, InputLabel, Select, Backdrop, CircularProgress, Autocomplete, } from "@mui/material";
import { AccountBalanceWallet, Cancel, PhoneIphone, Upload, ArrowBack, ArrowForward, } from "@mui/icons-material";
import { GetApi, postApi } from "../../Api/Api";
import { NewLogo, CircleCheckIcon, ftrLinkedin, OthersCategory } from "../../Components/Images/Images";
import { useSnackbar } from "../../Contexts/SnackbarContext";
import SliderCard from "../../Components/Common/SliderCard";

//country code file
import CountryCodes from "../../Data/countries.json";
import ConnectWalletInvestor from "../../Web3/ConnectWalletInvestor";
const country_codes = CountryCodes;

const disabledCss = {
  color: "#ccc",
  pointerEvents: "none" /* Disable pointer events on the link */,
  cursor: "not-allowed",
};

export default function RegisterDIC() {

  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [showOtp, setShowOtp] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const [emailOtp, setEmailOtp] = useState("");
  const [otpExpire, setOtpExpire] = useState("");
  const [step, setStep] = useState(1);
  const [combineData, setCombineData] = useState({});
  const [emailExist, setEmailExist] = useState(false);
  const [sentEmail, setSentEmail] = useState('');
  const [isSkipped, setIsSkipped] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [showMessage, setShowMessage] = useState('We’ve sent an OTP on your e-mail. Please enter the OTP below.');

  //Loading button
  const [loading, setLoading] = useState(false);
  const [otpTimer, setOtpTimer] = useState(0);

  // Primary Asset Class Expertise
  const [selectedPrimaryExpertise, setSelectedPrimaryExpertise] = useState("");
  // Secondary Asset Class Expertise
  const [selectedExpertise, setSelectedExpertise] = useState([]);

  // State to hold uploaded documents
  const [uploadedDocs, setUploadedDocs] = useState([]);

  // State for the profession
  const [profession, setProfession] = useState([]);

  const [othersPrimaryExpertise, setOthersPrimaryExpertise] = useState("");
  // const [othersSecondaryExpertise, setOthersSecondaryExpertise] = useState("");
  const [othersProfessionId, setOthersProfessionId] = useState(""); // to specially handle others type of profession
  const [othersProfessionName, setOthersProfessionName] = useState("");

  const { showSnackbar } = useSnackbar();

  // Get category data and profession data
  useEffect(() => {
    async function fetchData() {
      try {
        const res = await GetApi("/proposer/getCategory/");
        if (res?.data?.data) {
          let newData = res?.data?.data;
          let others = { id: 0, title: "Others", others_image: OthersCategory };
          newData.push(others);
          let defaultExpertise = [];
          newData?.forEach((item) => {
            defaultExpertise.push({ id: item.id, status: false });
          });
          setSelectedExpertise(defaultExpertise);
          setCategoryData(newData);
        }
      } catch (error) {
        console.log(error);
      }
    }

    async function getProfessionData() {
      try {
        const res = await GetApi("/committee/profession");
        if (res?.data?.data) {
          let newProfession = res?.data?.data;
          let others = { id: 0, profession_name: "Others" };
          newProfession.push(others);
          setProfession(newProfession);
        }
      } catch (error) {
        console.log(error);
      }
    }

    if (selectedExpertise?.length === 0) {
      fetchData();
    }
    getProfessionData();
  }, []);

  // Set otp expired time
  useEffect(() => {
    if (otpExpire) {
      const currTime = new Date().getTime() / 1000;
      setOtpTimer(Math.round(otpExpire / 1000 + 300 - currTime)); //timer countdown of otp
    }
  }, [otpExpire]);

  // Format the time in minutes and seconds
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  // Setting otp timer
  useEffect(() => {
    if (otpTimer > 0) {
      const timer = setInterval(() => {
        setOtpTimer(otpTimer - 1);
      }, 1000);

      // Clean up the timer when the component unmounts
      return () => clearInterval(timer);
    }
  }, [otpTimer]);

  /**
   * Function to handle resend OTP
   * @param {Event} event
   */
  const resendLink = (event) => {
    event.preventDefault();
    // Check if OTP timer is greater than 0 to prevent multiple resend requests
    if (otpTimer > 0) {
      return;
    }
    // Call the function to handle OTP resend
    event.preventDefault();
    handleOtp("resend");
  };

  /**
   * Function to prevent paste data
   * @param {Event} event
   */
  const handlePaste = (e) => {
    e.preventDefault();
    return false;
  };

  // Initializing useForm with default data
  const {
    register: mainForm,
    handleSubmit: mainSubmit,
    formState: { errors },
    getValues,
    watch,
    control,
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      countryCode: "",
    },
  });

  const emailPattern = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  const _email = watch("email");
  const firstName = watch("firstName");
  const lastName = watch("lastName");
  const countryCodeNum = watch("countryCode");
  const phoneNumber = watch("phoneNumber");

  const validName = (name) => {
    let pattern = /^[A-Za-z]+$/i;
    if (!name) {
      return false;
    } else if (name?.length < 2) {
      return false
    } else {
      return pattern.test(name)
    }
  }

  /**
   * set opt
   * @param {Number} otp 
   */
  const handleChange = (otp) => {
    setOtp(otp);
  };

  /**
   * Function for Getting Otp On email (SendOtp)
   * @param {Object} data
   */
  const handleOtp = async (data) => {
    const ValuesAll = getValues();
    setLoading(true);

    if (validatePhoneNumber(phoneNumber, countryCodeNum)) {
      setShowMessage("We’ve sent an OTP on your e-mail and phone number. Please enter the OTP below.")
    }


    try {
      const otpPayload = {
        email: ValuesAll?.email,
        firstName: ValuesAll?.firstName,
        lastName: ValuesAll?.lastName,
        phone_number: ValuesAll?.phoneNumber,
        country_code: `+${ValuesAll?.countryCode}`
      };
      setIsButtonClicked(true);
      const res = await postApi("/committee/otpSend", otpPayload);
      if (res?.data?.data && res?.data?.code === 200) {
        // setEmailOtp(res?.data?.data?.otp);
        setSentEmail(res?.data?.data?.email)
        if (res?.data?.data?.exits) {
          setEmailExist(res?.data?.data?.exits);
          showSnackbar(
            "This email is already registered with us. Please log in.",
            "error",
            3000
          );
          setIsButtonClicked(false);
        } else {
          setOtpExpire(new Date());
          setIsButtonClicked(true);
          setShowOtp(true);
          if (data === "send") {
            showSnackbar("OTP Sent Successfully.", "success");
          }
          if (data === "resend") {
            showSnackbar("OTP Resent Successfully.", "success");
          }
        }
        setLoading(false);
      } else if (res?.data?.code === 201) {
        setLoading(false);
        setIsButtonClicked(false);
        showSnackbar(res?.data?.message, 'error')
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      setIsButtonClicked(false);
    }
  };

  /**
   * Handle user data and otp verification
   * @param {Object} data
   */
  const onSubmitMain = async (data) => {
    if (!showOtp) {
      handleOtp("send");
      return
    }
    if (!showOtp) {
      showSnackbar("Please request an OTP.", "error");
      return;
    }
    if (Math.floor(new Date().getTime() / 1000) > otpExpire) {
      showSnackbar("OTP expired. Please request a new one.", "error");
      return;
    }
    if (otp.length !== 6 && emailOtp === "") {
      showSnackbar("Please enter valid OTP.", "error");
      return;
    }
    //  else if (otp !== `${emailOtp}`) {
    //   showSnackbar("Please enter correct OTP", "error");
    //   return;
    // }
    else if (emailExist) {
      showSnackbar(
        "This email is already registered with us. Please log in.",
        "error",
        3000
      );
      return;
    } else {
      try {
        const otpPayload = { email: sentEmail, otp };
        const res = await postApi("/committee/otpVerify", otpPayload);
        if (res?.data?.result && res?.data?.code === 200) {
          if (res?.data?.result) {
            setStep(2);
            setCombineData({ ...combineData, data });
            // showSnackbar(res?.data?.message, "success");
          }
        } else {
          showSnackbar(res?.data?.message, "error");
        }
      } catch (error) {
        console.log(error);
        // showSnackbar(error, "error");
      }
    }
  };

  /**
   * Handle primary expertise selection
   */
  const onSubmitPrimaryExpertise = () => {
    if (selectedPrimaryExpertise === "" && selectedPrimaryExpertise !== 0) {
      showSnackbar("Please select at least one category", "error");
      return;
    } else if (selectedPrimaryExpertise === 0 && !othersPrimaryExpertise) {
      showSnackbar("Please enter other category title", "error");
      return;
    } else {
      setCombineData({
        ...combineData,
        selectedPrimaryExpertise,
        othersPrimaryExpertise,
      });
      // setCombineData({ ...combineData, selectedPrimaryExpertise });
      setStep(3);
      return;
    }
  };

  /**
   * Handle primary expertise selection
   */
  const onSubmitExpertise = () => {
    setIsSkipped(false);
    if (
      !selectedExpertise?.some(
        (item) => item.status && item.id !== selectedPrimaryExpertise
      )
    ) {
      showSnackbar("Please select at least one category", "error");
      return;
    } else {
      let arrayOfIds = selectedExpertise
        ?.filter((item) => item.status === true)
        .map((item) => item.id);
      arrayOfIds = arrayOfIds?.filter(
        (item) => item !== selectedPrimaryExpertise
      );
      setCombineData({ ...combineData, selectedExpertise: arrayOfIds });
      setStep(4);
      return;
    }
  };

  /**
   * Handle selection of secondary expertise
   * @param {Integer} id
   */
  const handleSecondaryAsset = (id) => {
    // Clone the array
    const updatedItems = selectedExpertise?.map((item) => {
      // Find the object with the matching id
      if (item.id === id) {
        // Update the property
        return { ...item, status: !item.status };
      }
      // If the id doesn't match, return the original object
      return item;
    });

    // Set the updated array to state
    setSelectedExpertise(updatedItems);
  };

  // For others primary category title
  useEffect(() => {
    if (selectedPrimaryExpertise !== 0) {
      setOthersPrimaryExpertise("");
    }
  }, [selectedPrimaryExpertise]);

  // Initializing useForm to handle Extra or social Information of dic user
  const {
    register: extraForm,
    handleSubmit: extraSubmit,
    control: control2,
    formState: { errors: extraErrors },
    watch: watchProfession,
  } = useForm({
    defaultValues: {
      linkedin: "",
      portfolio: "",
      profession: "",
    },
  });

  /**
   * Callback function triggered when files are dropped
   */
  const onDrop = useCallback(
    (acceptedFiles) => {
      // Filter files based on size (maximum size: 12 MB)
      const acceptedFiles1 = acceptedFiles?.filter((images) => {
        return images.size <= 12000000;
      });
      // Display a warning if some files exceed the size limit
      if (acceptedFiles1.length != acceptedFiles.length) {
        showSnackbar(
          "Please upload an pdf with a maximum size of 12 MB.",
          "error"
        );
      }
      // Update the state with the filtered files
      let dataDetail = [...uploadedDocs, ...acceptedFiles1];
      setUploadedDocs(dataDetail);
    },
    [uploadedDocs]
  );

  const { acceptedFiles, getRootProps, open, getInputProps } = useDropzone({
    // maxFiles: 3,
    noClick: true,
    noKeyboard: true,
    accept: {
      "application/pdf": [".pdf"],
    },
    uploadMultiple: true,
    onDrop,
  });

  /**
   * Handle delete documents
   * @param {Object} file - documents file
   * @param {Integer} index - index value of uploadedDoc array
   */
  const handleDeleteDocument = (file, index) => {
    const newUploadedImages = uploadedDocs.filter(
      (image, ind) => ind !== index
    );
    setUploadedDocs(newUploadedImages);
  };

  // Rendering uploaded docs
  const files = uploadedDocs?.map((file, index) => (
    <Box className="showUploadedDocuments">
      <Box key={file.path} className="showUploadedDocuments-wrap">
        <Box className="fileName">{file.path}</Box>
        <Cancel
          onClick={() => handleDeleteDocument(file, index)}
          className="removeIcon"
        />
      </Box>
    </Box>
  ));

  // useEffect to watch for changes in the "profession" field
  useEffect(() => {
    // Get the current value when the "profession" field changes
    const professionValue = watchProfession("profession");
    setOthersProfessionId(professionValue);
    // Do something with the value
  }, [watchProfession("profession")]); // This will re-run the effect whenever the "profession" field changes

  /**
   * Onsubmit function for the data section.
   * @param {Object}
   * @returns
   */
  const onSubmitExtraInfo = async (data) => {
    if (!walletAddress) {
      showSnackbar("Please connect your wallet.", "error");
      return;
    }

    // setLoading(true)
    if (uploadedDocs.length === 0) {
      showSnackbar("Please upload document.", "error");
      return;
    }

    if (data && uploadedDocs.length > 0) {
      if (data?.profession === 0 && !othersProfessionName) {
        showSnackbar("Other profession name is required to proceed", "error");
        return;
      }
      let formData = new FormData();
      formData.append("country_code", `+${combineData?.data?.countryCode}`);
      formData.append("email", combineData?.data?.email);
      formData.append("first_name", combineData?.data?.firstName);
      formData.append("last_name", combineData?.data?.lastName);
      formData.append("phone_number", combineData?.data?.phoneNumber);
      formData.append("linkedin_url", data?.linkedin);
      formData.append("portfolio_url", data?.portfolio);
      formData.append("wallet_address", walletAddress);
      // For other profession name
      if (combineData?.selectedPrimaryExpertise === 0) {
        formData.append("other_primary", combineData?.othersPrimaryExpertise);
      } else {
        formData.append("category_id", combineData?.selectedPrimaryExpertise);
      }
      formData.append(
        "secondary_ids",
        isSkipped ? "" : JSON.stringify(combineData?.selectedExpertise)
      );
      // For other profession name
      if (data?.profession === 0) {
        formData.append("other_profession", othersProfessionName);
      } else {
        formData.append("profession_id", data?.profession);
      }
      // Append documents here
      uploadedDocs?.forEach((val) => {
        formData.append("document_file", val);
      });

      try {
        const res = await postApi("/committee/register", formData);
        if (res.data.code === 200) {
          setStep(5);
        }
        if (res?.data.code === 201) {
          showSnackbar(res?.data?.error, "error");
        }
      } catch (error) {
        console.log(error);
      }
    }
    return;
  };

  /**
   * Handle back to previous
   * @param {Event} e
   */
  const backButton = (e) => {
    e.preventDefault();
    setStep((prev) => {
      return prev - 1;
    });
  };

  /**
   * Component representing a LinkedIn link.
   * It renders a link to the LinkedIn sign-up page.
   */
  const linkedinUrl = (
    <Link
      to="https://www.linkedin.com/signup"
      target="_blank"
      rel="noopener"
      className="text-underline text-black"
    >
      here
    </Link>
  );

  //Wallet connection code start
  const [walletDialogOpen, setWalletDialogOpen] = useState(false);
  const [walletAddress, setWalletAddress] = useState("");

  const handleClickOpen = () => {
    setWalletDialogOpen(true);
  };

  const handleCloseDialog = (value) => {
    setWalletDialogOpen(false);
  };

  /**
   * gets called when a wallet is connect successfully
   * @param {string} acc
   */
  const walletLogin = async (acc) => {
    if (acc) {
      setWalletAddress(acc);
      showSnackbar("Wallet connected successfully", "success");
    }
  };

  //Wallet connection code end

  const [countryIso, setCountryIso] = useState("");

  /**
   *  validate phone number with libphonenumber-js library
   * @param {string} value  phone number
   * @param {string} country_iso  country code
   * @returns
   */
  const validatePhoneNumber = (value) => {
    const res = isSupportedCountry(countryIso);
    return isValidPhoneNumber(`+${countryCodeNum}${value}`, countryIso);
  };

  return (
    <>
      <Box className="register-page auth-page">
        <Box className="auth-section">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <Box className="auth-left">
                <Box className="content">
                  <Box className="heading-wrap">
                    <Typography component="h1" className="title">
                      Join RACE’s Decentralized Investment Committee
                    </Typography>
                    <Typography component="p" className="sub-title">
                      HELP DRIVE THE RACE PLATFORM • GET REWARDED FOR WORK DONE
                    </Typography>
                  </Box>
                </Box>

                <Box className="auth-logo">
                  <Link to="/">
                    <Box component="img" src={NewLogo} alt="Race logo" />
                  </Link>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              {step === 1 ? ( // Start Form Main
                <Box className={"auth-right"}>
                  <Box textAlign={"right"} className="alreadyAc-des">
                    Already have an account? Sign in{" "}
                    <Link component="a" to="/dic/login">
                      here
                    </Link>{" "}
                    <Box component="span" className="icon" ml={1}>
                      <ArrowForward />
                    </Box>
                  </Box>
                  <Box
                    className="auth-form-wrap"
                    component="form"
                    onSubmit={mainSubmit(onSubmitMain)}
                  >
                    <Box className="heading-wrap">
                      <Stack
                        direction={{ xs: "column", sm: "row" }}
                        useFlexGap
                        flexWrap="wrap"
                        justifyContent="space-between"
                      >
                        <Box className="left">
                          <Typography component="h1" className="title">
                            Join the Committee
                          </Typography>
                          <Typography component="p" className="sub-title">
                            Be a part of RACE’s success
                          </Typography>
                        </Box>
                      </Stack>
                    </Box>
                    <Box className="auth-form" mt={4}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Box className="form-group label-field">
                            <TextField
                              type="text"
                              id="outlined-required"
                              label="First Name"
                              placeholder="Enter your name"
                              {...mainForm("firstName", {
                                required: "First name is required.",
                                pattern: {
                                  value: /^[A-Za-z]+$/i,
                                  message: "Please enter valid first name",
                                },
                                minLength: {
                                  value: 1,
                                  message: "Please enter valid first name",
                                },
                              })}
                              autoComplete='given-name' // this line for auto-fill
                              error={Boolean(errors.firstName)}
                              helperText={errors.firstName?.message}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Box className="form-group label-field">
                            <TextField
                              type="text"
                              id="outlined-required"
                              label="Last Name"
                              name="lastName"
                              placeholder="Enter your surname"
                              {...mainForm("lastName", {
                                required: "Last name is required.",
                                pattern: {
                                  value: /^[A-Za-z]+$/i,
                                  message: "Please enter valid last name",
                                },
                                minLength: {
                                  value: 1,
                                  message: "Please enter valid last name",
                                },
                              })}
                              autoComplete="family-name" // this line for auto-fill
                              error={Boolean(errors.lastName)}
                              helperText={errors.lastName?.message}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                      <Box className="form-group label-field">
                        <TextField
                          id="outlined-required"
                          label="Email Address"
                          type="email"
                          name="email"
                          disabled={isButtonClicked ? true : false}
                          placeholder="Enter your email address"
                          {...mainForm("email", {
                            required: "E-mail address is required.",
                            pattern: {
                              value: emailPattern,
                              message: "Please enter valid email address.",
                            },
                          })}
                          InputProps={{
                            // endAdornment: (
                            // <InputAdornment position="end">
                            //   <IconButton
                            //     sx={{ height: "52px" }}
                            //     disabled={
                            //       emailPattern.test(_email) && validName(firstName) && validName(lastName) ? false : true
                            //     }
                            //     // onClick={() => {
                            //     //   setShowOtp(true);
                            //     // }}
                            //     onClick={() => {
                            //       handleOtp("send");
                            //     }}
                            //   >
                            //     <Typography
                            //       className="text-blue-600 fw-500"
                            //       style={isButtonClicked ? disabledCss : {}}
                            //     >
                            //       Send OTP
                            //     </Typography>
                            //   </IconButton>
                            // </InputAdornment>
                            // ),
                          }}
                          autoComplete="email" // this line for auto-fill
                          error={Boolean(errors.email)}
                          helperText={errors.email?.message}
                        />
                      </Box>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={5} md={3}>
                          <Controller
                            name="countryCode"
                            control={control}
                            defaultValue={country_codes[0].code}
                            rules={{
                              validate: value =>
                                phoneNumber
                                  ? value != '' || "Please select country code" : true,
                            }}
                            render={({ field: { onChange, value, ref } }) => (
                              <Autocomplete
                                options={country_codes}
                                getOptionLabel={(option) =>
                                  `+${option.code} (${option.iso})`
                                }
                                value={
                                  country_codes.find(
                                    (option) => option.code === value
                                  ) || null
                                }
                                onChange={(_, newValue) => {
                                  onChange(newValue ? newValue.code : "");
                                  setCountryIso(newValue ? newValue.iso : "");
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option.code === value.code
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    inputRef={ref}
                                    label="Country Code"
                                    placeholder="Select Country Code"
                                    error={Boolean(errors.countryCode)}
                                    helperText={errors.countryCode?.message}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    inputProps={{
                                      ...params.inputProps,
                                      inputMode: "numeric",
                                      type: "text", // Use text to allow proper Autocomplete function
                                      onInput: (event) => {
                                        event.target.value =
                                          event.target.value.replace(
                                            /[^\d]/g,
                                            ""
                                          );
                                      },
                                    }}
                                  />
                                )}
                              />
                            )}
                          />
                          {/* <Typography>
                            {!showOtp
                              ? (phoneNumber && countryCodeNum == "" ? "Please select country code"
                                : '')
                              : ''
                            }
                          </Typography> */}
                        </Grid>
                        <Grid item xs={12} sm={12} md={9}>
                          <Box className="form-group label-field">
                            <Controller
                              name="phoneNumber"
                              control={control}
                              defaultValue=""
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  label="Phone Number"
                                  type="text"
                                  pattern="[0-9]*"
                                  placeholder="Enter your phone number"
                                  autoComplete="off"
                                  error={Boolean(errors.phoneNumber)}
                                  helperText={errors?.phoneNumber?.message}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <PhoneIphone className="text-blue-600" />
                                      </InputAdornment>
                                    ),
                                  }}
                                  onInput={(event) => {
                                    event.target.value = event.target.value.replace(/\D/g, "").slice(0, 15);
                                  }}
                                />
                              )}
                              rules={{
                                validate: value =>
                                  countryCodeNum
                                    ? validatePhoneNumber(value, countryCodeNum) || "Please enter a valid phone number"
                                    : true,
                              }}
                            />
                            {/* <Typography>
                              {countryCodeNum && !showOtp
                                ? validatePhoneNumber(phoneNumber, countryCodeNum) ? "" : "Please enter a valid phone number"
                                : ''
                              }
                            </Typography> */}
                          </Box>

                        </Grid>
                      </Grid>

                      {showOtp ? (
                        <>
                          <Box className="otpSendText" mb={3}>
                            {showMessage}
                          </Box>
                          <Box className="auth-form" mt={4}>
                            <FormGroup
                              className="authFormGroup-otp"
                              sx={{ flexDirection: "row", mb: 4 }}
                            >
                              <OtpInput
                                value={otp}
                                renderInput={(props) => (
                                  <input
                                    className="form-control"
                                    {...props}
                                    onPaste={handlePaste} // Disable paste
                                    onCopy={handlePaste} // Disable copy
                                    onCut={handlePaste} // Disable cut
                                  />
                                )}
                                onChange={handleChange}
                                numInputs={6}
                                isInputNum={true}
                                placeholder="xxxxxx"
                                inputType="password"
                                className={"form-control otp-input"}
                                inputStyle={"form-control"}
                                shouldAutoFocus={true}
                              />
                            </FormGroup>
                            <Stack
                              className="alreadyAc-des"
                              direction="row"
                              justifyContent={"space-between"}
                              mb={3}
                            >
                              <Box>
                                Did not receive the OTP?{" "}
                                <Link
                                  to="#"
                                  style={otpTimer > 0 ? disabledCss : {}}
                                  onClick={resendLink}
                                >
                                  RESEND{" "}
                                </Link>
                              </Box>
                              <Typography>{formatTime(otpTimer)}</Typography>
                            </Stack>
                          </Box>{" "}
                        </>
                      ) : null}
                    </Box>


                    {!showOtp ? <Box className="btn-wrap">
                      <LoadingButton
                        loading={loading}
                        loadingPosition="start"
                        variant="contained"
                        type="submit"
                        fullWidth
                        className="btn-rounded btn-large btn-blue-600"
                      >
                        Send OTP
                      </LoadingButton>
                      {/* <Button
                        className="btn-rounded btn-large btn-blue-600"
                        sx={{ height: "52px" }}
                        disabled={
                          emailPattern.test(_email) && validName(firstName) && validName(lastName) && ((countryCodeNum || phoneNumber) ? validatePhoneNumber(phoneNumber, countryCodeNum) : true) ? false : true
                        }
                        // onClick={() => {
                        //   setShowOtp(true);
                        // }}
                        onClick={() => {
                          handleOtp("send");
                        }}
                      >
                        <Typography
                          className="text-white fw-500"
                          style={isButtonClicked ? disabledCss : {}}
                        >
                          Send OTP
                        </Typography>
                      </Button> */}
                    </Box>
                      : <Box className="btn-wrap">
                        <LoadingButton
                          loading={loading}
                          loadingPosition="start"
                          variant="contained"
                          type="submit"
                          fullWidth
                          className="btn-rounded btn-large btn-blue-600"
                        >
                          Next
                        </LoadingButton>
                      </Box>
                    }
                  </Box>
                </Box>
              ) : step === 2 ? ( // Primary Asset Class Expertise
                <Box className={"auth-right"}>
                  <Box textAlign={"left"} className="alreadyAc-des">
                    <Link component="a" onClick={backButton}>
                      <Box component="span" className="icon" ml={1}>
                        <ArrowBack />
                      </Box>
                      Back
                    </Link>{" "}
                  </Box>
                  <Box className="auth-form-wrap">
                    <Box className="heading-wrap">
                      <Stack
                        direction={{ xs: "column", sm: "row" }}
                        useFlexGap
                        flexWrap="wrap"
                        justifyContent="space-between"
                      >
                        <Box className="left">
                          <Typography component="h1" className="title">
                            Primary Asset Class Expertise
                          </Typography>
                          <Typography component="p" className="sub-title">
                            Please select only one main expertise.
                          </Typography>
                        </Box>
                      </Stack>
                    </Box>
                    <Box className="auth-form" mt={4}>
                      <Box
                        className="questionnaire5 qsn-middle-content"
                        sx={{ mb: 5 }}
                      >
                        <Box className="CategoryBox">
                          <Grid container spacing={2}>
                            {categoryData?.map((item, index) => {
                              return (
                                <Grid
                                  item
                                  xs={6}
                                  sm={6}
                                  md={4}
                                  key={index}
                                  onClick={() => {
                                    setSelectedPrimaryExpertise(item.id);
                                  }}
                                  className={
                                    item?.id === selectedPrimaryExpertise
                                      ? "selected"
                                      : ""
                                  }
                                >
                                  <SliderCard
                                    image={
                                      item?.category_image
                                        ? `${process.env.REACT_APP_IMAGE_URL}${item?.category_image?.path}`
                                        : item?.others_image
                                    }
                                    title={item?.title}
                                  />
                                </Grid>
                              );
                            })}
                          </Grid>
                        </Box>
                        {selectedPrimaryExpertise === 0 ? (
                          <Box sx={{ mt: "30px" }}>
                            <TextField
                              id="outlined-basic"
                              label="Other Asset"
                              variant="outlined"
                              name="others_title"
                              value={othersPrimaryExpertise}
                              onChange={(e) =>
                                setOthersPrimaryExpertise(e.target.value)
                              }
                            />
                          </Box>
                        ) : null}
                      </Box>
                    </Box>

                    <Box className="btn-wrap">
                      <LoadingButton
                        onClick={onSubmitPrimaryExpertise}
                        loading={loading}
                        loadingPosition="start"
                        variant="contained"
                        type="submit"
                        fullWidth
                        className="btn-rounded btn-large btn-blue-600"
                      >
                        Next
                      </LoadingButton>
                    </Box>
                  </Box>
                </Box>
              ) : step === 3 ? ( // Secondary Asset Class Expertise
                <Box className={"auth-right"}>
                  <Box textAlign={"left"} className="alreadyAc-des">
                    <Link component="a" onClick={backButton}>
                      <Box component="span" className="icon" ml={1}>
                        <ArrowBack />
                      </Box>
                      Back
                    </Link>{" "}
                  </Box>
                  <Box className="auth-form-wrap">
                    <Box className="heading-wrap">
                      <Stack
                        direction={{ xs: "column", sm: "row" }}
                        useFlexGap
                        flexWrap="wrap"
                        justifyContent="space-between"
                      >
                        <Box className="left">
                          <Typography component="h1" className="title">
                            Secondary Asset Class Expertise
                          </Typography>
                          <Typography component="p" className="sub-title">
                            Please select the one(s) that apply, or skip if you
                            only have a single expertise.
                          </Typography>
                        </Box>
                      </Stack>
                    </Box>
                    <Box className="auth-form" mt={4}>
                      <Box
                        className="questionnaire5 qsn-middle-content"
                        sx={{ mb: 5 }}
                      >
                        <Box className="CategoryBox">
                          <Grid container spacing={2}>
                            {categoryData.map((item, index) => {
                              return (
                                <Grid
                                  item
                                  xs={6}
                                  sm={6}
                                  md={4}
                                  key={index}
                                  onClick={() =>
                                    selectedPrimaryExpertise === item.id
                                      ? ""
                                      : handleSecondaryAsset(item.id)
                                  }
                                  display={
                                    (selectedPrimaryExpertise === item.id ||
                                      item.id === 0) &&
                                    "none"
                                  }
                                  className={
                                    selectedExpertise[index] &&
                                      selectedExpertise[index].status
                                      ? "selected"
                                      : ""
                                  }
                                  sx={{
                                    disabled:
                                      selectedPrimaryExpertise === item.id,
                                  }}
                                >
                                  <SliderCard
                                    image={
                                      item?.category_image
                                        ? `${process.env.REACT_APP_IMAGE_URL}${item?.category_image?.path}`
                                        : item?.others_image
                                    }
                                    title={item?.title}
                                  />
                                </Grid>
                              );
                            })}
                          </Grid>
                        </Box>
                      </Box>
                    </Box>
                    <Stack>
                      <Grid container spacing={{ xs: 1 }}>
                        <Grid item xs={6} md={6}>
                          <Box className="btn-wrap">
                            <LoadingButton
                              onClick={() => {
                                setStep(4);
                                setIsSkipped(true);
                                setCombineData({
                                  ...combineData,
                                  selectedExpertise: [],
                                });
                                let defaultExpertise = [];
                                categoryData?.forEach((item) => {
                                  defaultExpertise.push({
                                    id: item.id,
                                    status: false,
                                  });
                                });
                                setSelectedExpertise(defaultExpertise);
                              }}
                              loading={loading}
                              loadingPosition="start"
                              variant="contained"
                              type="submit"
                              fullWidth
                              className="btn-rounded btn-large btn-blue-600"
                              sx={{
                                background: "#E2E8F0 !important",
                                color: "#053863 !important",
                              }}
                            >
                              <IconButton color="inherit">
                                <AccountBalanceWallet />
                              </IconButton>
                              Skip
                            </LoadingButton>
                          </Box>
                        </Grid>
                        <Grid item xs={6} md={6}>
                          <Box className="btn-wrap">
                            <LoadingButton
                              onClick={onSubmitExpertise}
                              loading={loading}
                              loadingPosition="start"
                              variant="contained"
                              type="submit"
                              fullWidth
                              className="btn-rounded btn-large btn-blue-600"
                            >
                              Next
                            </LoadingButton>
                          </Box>
                        </Grid>
                      </Grid>
                    </Stack>
                  </Box>
                </Box>
              ) : step === 4 ? ( // Submitted application
                <Box className={"auth-right"}>
                  <Box textAlign={"left"} className="alreadyAc-des">
                    <Link component="a" onClick={backButton}>
                      <Box component="span" className="icon" ml={1}>
                        <ArrowBack />
                      </Box>
                      Back
                    </Link>{" "}
                  </Box>
                  <Box
                    className="auth-form-wrap"
                    component="form"
                    onSubmit={extraSubmit(onSubmitExtraInfo)}
                  >
                    <Box className="heading-wrap">
                      <Stack
                        direction={{ xs: "column", sm: "row" }}
                        useFlexGap
                        flexWrap="wrap"
                        justifyContent="space-between"
                      ></Stack>
                    </Box>
                    <Box className="auth-form" mt={4}>
                      <Box
                        className="form-group label-field"
                        sx={{
                          paddingBottom: "8px",
                          display: "flex",
                          alignItems: "top",
                          gap: "10px",
                        }}
                      >
                        <TextField
                          disabled
                          type="url"
                          id="outlined-required"
                          label="Wallet Address"
                          value={walletAddress}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <AccountBalanceWallet />
                              </InputAdornment>
                            ),
                          }}
                        />
                        <Box mt={"6px"}>
                          {walletAddress ? (
                            <Button
                              disabled
                              style={{ width: "140px" }}
                              className="btn-rounded btn-green-400"
                              onClick={handleClickOpen}
                            >
                              Wallet Connected
                            </Button>
                          ) : (
                            <Button
                              style={{ width: "140px" }}
                              className="btn-rounded btn-golden"
                              onClick={handleClickOpen}
                            >
                              Connect Wallet
                            </Button>
                          )}
                        </Box>
                      </Box>

                      <Box className="form-group label-field">
                        <TextField
                          // type="url"
                          id="outlined-required"
                          label="Linkedin"
                          placeholder="https://www.linkedin.com/"
                          {...extraForm("linkedin", {
                            required: "Linked Url is required.",
                            pattern: {
                              value: /^https:\/\/(www\.)?linkedin\.com\/.*$/,
                              message: "Please enter a valid Linkedin URL",
                            },
                          })}
                          // autoComplete="url"
                          error={Boolean(extraErrors.linkedin)}
                          // helperText={errors.firstName?.message}
                          helperText={
                            extraErrors.linkedin ? (
                              "Please enter valid Linkedin URL"
                            ) : (
                              <>
                                A Linkedin account is required. Sign up{" "}
                                {linkedinUrl}
                              </>
                            )
                          }
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Avatar
                                  alt="Linkedin Image"
                                  src={ftrLinkedin}
                                  sx={{ height: "24px", width: "24px" }}
                                />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                      {/* Making portfolio link optional */}
                      <Box className="form-group label-field">
                        <TextField
                          // type="url"
                          id="outlined-required"
                          label="Portfolio"
                          placeholder="Link to portfolio"
                          {...extraForm("portfolio", {
                            pattern: {
                              value: /^(https?|ftp):\/\/[\w.-]+\.[a-z]{2,}/i,
                              message: "Please enter a valid Portfolio URL",
                            },
                          })}
                          // autoComplete="url"
                          error={Boolean(extraErrors.portfolio)}
                          helperText={
                            extraErrors.portfolio
                              ? extraErrors.portfolio?.message
                              : "This field is optional. You can leave it blank if you don't have a portfolio URL."
                          }
                        />
                      </Box>
                      <Box className="form-group label-field" mb={4}>
                        <Controller
                          name="profession"
                          control={control2}
                          rules={{
                            required: "Profession is required.",
                          }}
                          render={({ field }) => (
                            <FormControl fullWidth>
                              <InputLabel htmlFor="profession">
                                Profession
                              </InputLabel>
                              <Select
                                {...field}
                                label="Profession"
                                error={Boolean(extraErrors.profession)}
                                value={field.value}
                                onChange={(e) => {
                                  field.onChange(e);
                                }}
                                MenuProps={{
                                  PaperProps: {
                                    style: {
                                      maxHeight: 200, // Set your desired max height
                                    },
                                  },
                                }}
                              >
                                {profession?.map((state) => (
                                  <MenuItem key={state.id} value={state.id}>
                                    {state.profession_name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          )}
                        />
                        {othersProfessionId === 0 ? (
                          <Box sx={{ mt: "30px" }}>
                            <TextField
                              id="outlined-basic"
                              label="Others Profession Name"
                              variant="outlined"
                              name="others_title"
                              value={othersProfessionName}
                              onChange={(e) =>
                                setOthersProfessionName(e.target.value)
                              }
                            />
                          </Box>
                        ) : null}
                      </Box>

                      <Box className="kyc-uploadDoc bg-blueGray-100" mb={2}>
                        <Box className="ctm-dragDrop" px={2}>
                          <Grid
                            container
                            spacing={{ xs: 1, md: 3 }}
                            alignItems={"center"}
                          >
                            <Grid item xs={12} sm={6} md={6}>
                              <Box
                                {...getRootProps({
                                  className: "dropzone",
                                })}
                                width={"100%"}
                                height={"118px"}
                                display={"flex"}
                                alignItems={"center"}
                                justifyContent={"center"}
                              >
                                <input {...getInputProps()} />
                                <Stack direction="row" alignItems={"center"}>
                                  <Box className="upload-icon">
                                    <Upload className="text-black" />
                                  </Box>
                                  <Box className="dD-heading" ml={3}>
                                    <Typography className="title fw-500 font-16 text-blue-900">
                                      Drag File here to upload
                                    </Typography>
                                    <Typography
                                      className="subtitle font-12 text-blueGray-600"
                                      mt={0.2}
                                    >
                                      or click the button below to browse
                                    </Typography>
                                  </Box>
                                </Stack>
                              </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                              <Box
                                className="fileUpload-button"
                                maxWidth={"264px"}
                                mx={"auto"}
                                mb={{ xs: "20px" }}
                              >
                                <Box
                                  {...getRootProps({
                                    className: "dropzone",
                                  })}
                                >
                                  <input {...getInputProps()} />
                                  <Button
                                    startIcon={<Upload />}
                                    onClick={open}
                                    fullWidth
                                    className="btn-rounded btn-blueGray-200"
                                  >
                                    Browse Files
                                  </Button>
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                        <Box p={2} pt={1.5}>
                          <Typography className="dD-title text-black font-18">
                            Upload Documents
                          </Typography>
                          <Typography
                            className="dD-subtitle font-12 text-blueGray-500"
                            mt={1}
                          >
                            Resume, Portfolio, Track Record etc.
                          </Typography>
                          <Typography
                            className="dD-subtitle font-12 text-blueGray-500"
                            mt={1.5}
                          >
                            Supported formats: PDF
                          </Typography>
                          {files != "" ? (
                            <Box
                              // direction="row"
                              // alignItems={"center"}
                              mt={3}
                            // overflow={"hidden"}
                            >
                              {files?.map((files) => {
                                return (
                                  <Box
                                    display={"flex"}
                                    alignItems={"center"}
                                    mt={1}
                                  >
                                    <Avatar
                                      src={CircleCheckIcon}
                                      alt="Icon"
                                      sx={{ width: 24, height: 24 }}
                                    />
                                    <Box
                                      className="dD-file-name text-black"
                                      ml={1}
                                    >
                                      {files}
                                    </Box>
                                  </Box>
                                );
                              })}
                            </Box>
                          ) : (
                            ""
                          )}
                        </Box>
                      </Box>
                    </Box>
                    <Box className="btn-wrap">
                      <LoadingButton
                        loading={loading}
                        loadingPosition="start"
                        variant="contained"
                        type="submit"
                        fullWidth
                        className="btn-rounded btn-large btn-blue-600"
                      >
                        Submit Application
                      </LoadingButton>
                    </Box>
                  </Box>
                  {walletDialogOpen ? (
                    <ConnectWalletInvestor
                      open={walletDialogOpen}
                      handleCloseDialog={handleCloseDialog}
                      handleConnectedSuccess={walletLogin}
                      userRegister={true}
                    />
                  ) : null}
                </Box>
              ) : (
                // Return to home
                <Box
                  className={"auth-right"}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Box className="heading-wrap">
                    <Stack
                      direction={{ xs: "column", sm: "row" }}
                      useFlexGap
                      flexWrap="wrap"
                      justifyContent="space-between"
                    >
                      <Box className="left">
                        <Typography component="h1" className="title">
                          Application Submitted!
                        </Typography>
                        <Typography component="p" className="sub-title">
                          You will be notified of the outcome via email. If your
                          application is approved, you’ll receive your login
                          credentials in the same email.
                        </Typography>
                      </Box>
                    </Stack>
                  </Box>
                  <Box className="btn-wrap" mt={5}>
                    <LoadingButton
                      onClick={() => navigate("/")}
                      loading={loading}
                      loadingPosition="start"
                      variant="contained"
                      fullWidth
                      className="btn-rounded btn-large btn-blue-600 "
                      sx={{ color: "white !important" }}
                    >
                      Return to home
                    </LoadingButton>
                  </Box>
                </Box>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* full page loader */}
      {loading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="primary" />
        </Backdrop>
      )}
    </>
  );
}
