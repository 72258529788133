import React from "react";
import { Link } from "react-router-dom";
import { Box, Grid, Typography, Button, TextField, FormControl, InputAdornment, } from "@mui/material";
import { KeyboardArrowRight, AttachMoney } from "@mui/icons-material";
import { useForm } from "react-hook-form";

// Import custom components for this page
import { NewLogo } from "../../../Components/Images/Images";
import LinearProgressCompo from "./Components/LinearProgressCompo";
import "./Components/Questionnaire.css";

export default function WatchDetails(props) {
    // Destructuring props to get functions and data required for the component
    const { handleNext, handleback, getProposalData, updateProposalData, saveProposal, getProgress } = props;

    // Retrieve current proposal data and progress
    var propData = getProposalData();
    let currentProgress = getProgress();

    // useForm hook to handle form validation and submission
    const { register, handleSubmit, formState: { errors } } = useForm({
        mode: 'onChange',
        defaultValues: {
            watch_condition: propData?.watchInformation?.watch_condition ? propData?.watchInformation?.watch_condition : "",
        }
    });

    /**
     * Function to handle form submission, updating 'propData' with the form data, and proceeding to the next step or saving and exiting.
     * @param {Object} data - The form data.
     * @param {Object} event - The form submission event.
     */
    const onSubmit = (data, event) => {
        const buttonText = event.nativeEvent.submitter.innerText;

        // Update 'propData' with the form data
        propData.watchInformation = data;

        // Set watch model as asset title
        propData.asset_title = data.watch_model;

        // Set watch address to null
        propData.asset_address = '';

        // Convert string to numeric for watch estimated value
        const stringWithoutCurrency = data?.watch_estimated.replace(/[^0-9.]/g, ''); // Removes non-numeric characters
        const integerValue = parseFloat(stringWithoutCurrency, 10); // Parses the string as a float
        propData.watchInformation.watch_estimated = integerValue;

        // Update the main page 
        updateProposalData(propData);

        // Proceed to the next step or save and exit based on the button clicked
        if (buttonText.includes("Save and Exit")) {
            SaveAndExit();
        } else {
            handleNext();
        }
    }

    /**
     * Function to save the current proposal data and exit the current step/page.
     */
    const SaveAndExit = () => {
        saveProposal();
    }


    return (
        <Box className="projectProposer questionnaire-page">
            <Box className="questionnaire-section">
                <Grid container spacing={0}>
                    <Grid item xs={12} sm={6} md={6}>
                        <Box className="auth-left">
                            {/* left-title */}
                            <Box className="quests-left-title">
                                <Typography component="h1" className="title">
                                    Next, we need some details of your assets
                                </Typography>
                            </Box>
                            {/* Logo */}
                            <Box className="auth-logo">
                                <Link to="/">
                                    <Box component="img" src={NewLogo} alt="Race logo" />
                                </Link>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <Box className="auth-right quests-right-contents-box" component="form"
                            onSubmit={handleSubmit(onSubmit)}>
                            <Box textAlign={"right"}>
                                <Box component="span" className="icon" ml={1}>
                                    <Button id="my-button" className="btn-rounded btn-text-white btn-blue-600 saveAndExit" type="submit">
                                        Save and Exit
                                    </Button>
                                </Box>
                            </Box>
                            {/*Watch information Form starts from here */}
                            <Box className="questionnaire6 qsn-middle-content" sx={{ pt: 4, mb: 5 }} >
                                <Box className="auth-form" mt={4} >
                                    <Box className="form-group label-field">
                                        <TextField
                                            defaultValue={propData?.watchInformation?.watch_model}
                                            autoComplete="on"
                                            type="text"
                                            id="outlined-required"
                                            label='Model Name'
                                            {...register('watch_model', {
                                                required: 'Model Name is required'
                                            })}
                                            error={Boolean(errors.watch_model)}
                                            helperText={errors.watch_model?.message}
                                        />
                                    </Box>
                                    <Box className="form-group label-field">
                                        <TextField
                                            defaultValue={propData?.watchInformation?.watch_brand}
                                            autoComplete="on"
                                            type="text"
                                            id="outlined-required"
                                            label={'Brand'}
                                            {...register("watch_brand", {
                                                required: "brand is required.",
                                            })}
                                            error={Boolean(errors.watch_brand)}
                                            helperText={errors.watch_brand?.message}
                                        />
                                    </Box>
                                    <Box className="form-group label-field">
                                        <TextField
                                            defaultValue={propData?.watchInformation?.watch_estimated}
                                            autoComplete="on"
                                            // type="text"
                                            id="outlined-required"
                                            label="Estimate value at purchase"
                                            InputProps={
                                                {
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <AttachMoney />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            {...register("watch_estimated", {
                                                required: "Estimated Value is required.",
                                                pattern: {
                                                    value: /^[+]?([0-9]*[.])?[0-9]+$/,  // Allow positive numeric values with one dot
                                                    message: "Please enter a valid numeric value.",
                                                },
                                            })}
                                            error={Boolean(errors.watch_estimated)}
                                            onInput={(event) => {
                                                event.target.value = event.target.value
                                                    .replace(/[^\d.]/g, "")
                                            }}
                                            helperText={errors.watch_estimated?.message}
                                        />
                                    </Box>
                                    <Box className="form-group label-field">
                                        <TextField
                                            defaultValue={propData?.watchInformation?.watch_year}
                                            type="text"
                                            autoComplete="on"
                                            id="outlined-required"
                                            label="Year"
                                            {...register("watch_year", {
                                                required: "year is required.",
                                                pattern: {
                                                    value: /^\d{4}$/,
                                                    message: "Please enter a valid year.",
                                                },
                                            })}
                                            onInput={(event) => {
                                                event.target.value = event.target.value
                                                    .replace(/\D/g, "")
                                                    .slice(0, 4);
                                            }}
                                            error={Boolean(errors.watch_year)}
                                            helperText={errors.watch_year?.message}
                                            onKeyDown={(event) => {
                                                if (event?.key === '-' || event?.key === '+') {
                                                    event.preventDefault();
                                                }
                                            }}
                                        />
                                    </Box>
                                    <Box>
                                        <FormControl fullWidth>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                label="Condition"
                                                id="outlined-select-country-native"
                                                select
                                                InputLabelProps={{ shrink: true }}
                                                SelectProps={{
                                                    native: true
                                                }}
                                                name='condition'
                                                {...register("watch_condition", {
                                                    required: "Condition is required.",
                                                })}
                                                error={Boolean(errors.watch_condition)}
                                                helperText={errors.watch_condition?.message}
                                                className='stIf-input'
                                                sx={{ input: { color: '#64748B !important' } }}
                                            >  <option value={""} >
                                                    Select Condition
                                                </option>
                                                <option value={"Brand New"}>
                                                    Brand New
                                                </option>
                                                <option value={"Excellent"}>
                                                    Excellent
                                                </option>
                                                <option value={"Good"}>
                                                    Good
                                                </option>
                                                <option value={"Fair"}>
                                                    Fair
                                                </option>
                                                <option value={"Poor"}>
                                                    Poor
                                                </option>
                                            </TextField>
                                        </FormControl>
                                    </Box>
                                </Box>
                            </Box>
                            <Box>
                                <Box className="questionnaire-progress" >
                                    <LinearProgressCompo value={currentProgress} />
                                </Box>
                                <Box
                                    sx={{ display: "flex", justifyContent: "space-between" }}
                                    mt={3}
                                >
                                    <Box textAlign={"left"} className="quests6-btn-box">
                                        <Button className='btn-rounded back-btn' onClick={handleback}>Back</Button>
                                    </Box>
                                    <Box textAlign={"right"} className="quests-btn-box">
                                        <Button
                                            id="my-button"
                                            // onClick={handleNext}
                                            endIcon={<KeyboardArrowRight />}
                                            className="btn-rounded btn-text-white btn-blue-600"
                                            type="submit"
                                        >
                                            Next
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box >
    );
}
